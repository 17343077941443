import React from 'react'

import { Checkbox, Form, Input, Button, Message, Select, TextArea } from 'semantic-ui-react'
import * as Theme from '../Elements/theme'

import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const destinationType = [
  { key: 'aktywny', text: 'Aktywny', value: 'Aktywny' },
  { key: 'turystyczny', text: 'Turystyczny', value: 'Turystyczny' },
  { key: 'edukacyjny', text: 'Edukacyjny', value: 'Edukacyjny' },
  { key: 'inny', text: 'Inny', value: 'Inny' },
]

const tripType = [
  { key: 'góry', text: 'Góry', value: 'Góry' },
  { key: 'morze', text: 'Morze', value: 'Morze' },
  { key: 'jeziora', text: 'Jeziora', value: 'Jeziora' },
  { key: 'pozostałe', text: 'Pozostałe', value: 'Pozostałe' },
]

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isValidated: false,
      Imię_i_Nazwisko: '',
      Numer_Telefonu: '',
      Adres_email: '',
      Nazwa_Szkoły: '',
      Adres_Szkoły: '',
      Ilu_uczęstników: '',
      Wiek_Klasa: '',
      Ilu_nauczycieli: '',
      Proponowany_termin: '',
      Liczba_dni: '',
      Sugerowane_miejsce: '',
      Charakter_wyjazdu: '',
      Dodatkowe_informacje: '',
      privacyCheck: false,
      errMessage: false
     };
  }
  
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = () => {
    this.setState({ 
      privacyCheck: !this.state.privacyCheck,
      errMessage: !this.state.errMessage,
     })
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.privacyCheck === true) {
      const form = e.target;
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch(error => alert(error));
    } else {
      this.setState({ 
        errMessage: true
       })
    }
  };

  render() {
    return (
      <Form 
        name="Wyślij Zapytanie"
        method="post"
        action="/dziekujemy/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        style={{ fontFamily: Theme.FontFamily.SubHeading, fontSize: Theme.Font.Size.Form, fontWeight: 400 }}
      >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
       <input type="hidden" name="form-name" value="contact"/>
       <div hidden>
         <label>
           Don’t fill this out:{" "}
           <input name="bot-field" onChange={this.handleChange} />
         </label>
       </div>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Imię i Nazwisko'
            name='Imię_i_Nazwisko'
            onChange={this.handleChange}
            required
            type='text'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Numer Telefonu'
            name='Numer_Telefonu'
            onChange={this.handleChange}
            required
            type='tel'
          />
          <Form.Field
            control={Input}
            label='Adres email'
            name='Adres_email'
            onChange={this.handleChange}
            required
            type='email'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Nazwa Szkoły'
            name='Nazwa_Szkoły'
            onChange={this.handleChange}
            required
            type='text'
          />
          <Form.Field
            control={Input}
            label='Adres Szkoły'
            name='Adres_Szkoły'
            onChange={this.handleChange}
            required
            type='text'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Ilu uczęstników (bez nauczycieli)'
            name='Ilu_uczęstników'
            onChange={this.handleChange}
            required
            type='number'
            min='1'
            max='99'
          />
          <Form.Field
            control={Input}
            label='Wiek / Klasa'
            name='Wiek_Klasa'
            onChange={this.handleChange}
            required
            type='text'
          />
          <Form.Field
            control={Input}
            label='Ilu nauczycieli'
            name='Ilu_nauczycieli'
            onChange={this.handleChange}
            required
            type='number'
            min='1'
            max='99'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Proponowany termin'
            name='Proponowany_termin'
            onChange={this.handleChange}
            required
            type='date'
          />
          <Form.Field
            control={Input}
            label='Liczba dni'
            name='Liczba_dni'
            onChange={this.handleChange}
            required
            type='number'
            min='1'
            max='20'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Select}
            label='Sugerowane miejsce'
            name='Sugerowane_miejsce'
            onChange={this.handleChange}
            options={tripType}
            required
          />
          <Form.Field
            control={Select}
            label='Charakter wyjazdu'
            name='Charakter_wyjazdu'
            onChange={this.handleChange}
            options={destinationType}
            required
          />
        </Form.Group>
        <Form.Field
          control={TextArea}
          rows={10}
          label='Dodatkowe informacje'
          name='Dodatkowe_informacje'
          onChange={this.handleChange}
        />
        <Form.Field>
          <Checkbox 
            label='Wyrażam zgodę na kontakt mailowy lub telefoniczny ze strony pracownika odpowiednich działów Kapiteam.'
            checked={this.state.privacyCheck}
            onChange={this.handleCheck}  
          />
        </Form.Field>
        <Button 
          color='teal'
          style={{ fontFamily: Theme.FontFamily.SubHeading, fontSize: Theme.Font.Size.Contact, fontWeight: 400 }}
          type='submit'
          >Wyślij
        </Button>
        <Message negative hidden={!this.state.errMessage}>
          <Message.Header>Wystąpił błąd</Message.Header>
          <p>Prosimy o wyrażenie zgody na kontakt przed wysłaniem formularza.</p>
        </Message>
      </Form>
    )
  }
}