import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/ContactForm-Sec'
import HeroSmall from '../components/HeroSmall'
import { Spacer } from '../components/Elements'

const ContactPage = () => (
  <StaticQuery
  query={graphql`
    query {
      datoCmsContactInfo {
        id
        emailPrimary
        phonePrimary
        phoneSecondary
        location {
          id
          name
          street
          city
          geolocation {
            latitude
            longitude
          }
        }
        socialFacebook
        socialInstagram
        socialYoutube
      }
      datoCmsHeadersSection(name: { eq: "kontakt" }) {
        id
        name
        header
        heroImage {
          fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed
          }
          fluid(maxWidth: 1200, maxHeight: 460) {
          ...GatsbyDatoCmsFluid
          }
        }
      }
      datoCmsGeneralText {
        secondaryContactMainNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `}
  render={data => (
  <Layout>
    <SEO title='Wyślij Zapytanie' />
    <HeroSmall data={ data.datoCmsHeadersSection } />
    <Grid style={{ paddingBottom: '3rem' }}>
      <Grid.Row centered>
        <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
          <div dangerouslySetInnerHTML={{ __html: data.datoCmsGeneralText.secondaryContactMainNode.childMarkdownRemark.html }}></div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid centered stackable>
      <Grid.Row>
        <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
          <ContactForm />
        </Grid.Column>
      </Grid.Row>
      <Spacer />
    </Grid>
  </Layout>
  )}
/>
)

export default ContactPage